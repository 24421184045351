
*{
  margin: 0;
  font-family: var(--ufFontReg);
  background: transparent;
}
:root{
  --ufOrange: #f37021;
  --ufBlue: #005496;
  --ufFontBold: "GentonaBold";
  --ufFontReg: "GentonaRegular";
  --ufFontLight: "GentonaLight";
  --bgImgUrl: url("./static/layered-waves-blue.svg");
  --containerColor: rgba(255,255,255,0.3);
}
html,body {
  margin: 0;
  height: 100%;
  padding: 0;
  overflow: hidden;
}
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
/* } 

 /* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}  */
.display-linebreak {
  white-space: pre-line;
}

#root{
  height: 100%;
}

@font-face{
  font-family: 'GentonaBold';
  src: url('fonts/Gentona-Bold.eot');
  src: url('fonts/Gentona-Bold.eot?#iefix') format('embedded-opentype'),
       url('fonts/Gentona-Bold.woff2') format('woff2'),
       url('fonts/Gentona-Bold.woff') format('woff'),
       url('fonts/Gentona-Bold.ttf') format('truetype'),
       url('fonts/Gentona-Bold.svg#Gentona-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'GentonaLight';
  src: url('fonts/Gentona-Light.eot');
  src: url('fonts/Gentona-Light.eot?#iefix') format('embedded-opentype'),
      url('fonts/Gentona-Light.woff2') format('woff2'),
      url('fonts/Gentona-Light.woff') format('woff'),
      url('fonts/Gentona-Light.ttf') format('truetype'),
      url('fonts/Gentona-Light.svg#Gentona-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'GentonaRegular';
  src: url('fonts/Gentona-Book.eot');
  src: url('fonts/Gentona-Book.eot?#iefix') format('embedded-opentype'),
      url('fonts/Gentona-Book.woff2') format('woff2'),
      url('fonts/Gentona-Book.woff') format('woff'),
      url('fonts/Gentona-Book.ttf') format('truetype'),
      url('fonts/Gentona-Book.svg#Gentona-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.uf_button{
  font-family: 'GentonaBold',Georgia !important;
  border:3px solid #f37021 !important;
  color: #f37021 !important;
  font-size: 16px !important;
  padding: 15px 20px !important;
  font-weight: 700 !important;
  letter-spacing: 2px !important;
  line-height: 1px !important;
  text-transform: uppercase !important;
  height: 50px !important;
  background-color: transparent !important;
  border-radius: 0px !important;
}

.uf_button:hover{
  background-color: var(--ufOrange) !important;
  color: white !important;
}
.uf_head{
  color: var(--ufBlue);
  font-family: var(--ufFontBold);
  text-transform: uppercase;
}

.uf_subheading{
  color: var(--ufOrange);
  font-family: var(--ufFontReg);
  text-transform: uppercase;
  font-size: larger;
}

/* .uf_toggleparagraph{
  max-height: 0;
  opacity: 0;
  transition: max-height 0.6s ease, opacity 0.5s ease;
}
.uf_toggleparagraphcontainer:hover .uf_toggleparagraph{
  max-height: 300px;
  opacity: 1;
} */

.uf_toggleparagraphcontainer {
  position: relative;
  overflow: hidden; /* Ensures no content from one container spills into others */
}

.uf_toggleparagraph {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.6s ease, opacity 0.5s ease;
  overflow: hidden; /* Ensures the content stays within bounds */
}

.uf_toggleparagraphcontainer:hover .uf_toggleparagraph {
  max-height: 300px;
  opacity: 1;
  z-index: 1; /* Ensures the hovered container is on top */
  position: relative; /* Makes sure each hovered content behaves independently */
}


.uf_navbar{
  background-color: var(--ufOrange);
  color:white;
  font-family: var(--ufFontBold);
}

.uf_textbox{
  background: #fff;
  border: 1px solid var(--ufOrange) !important;
  color: #f37021 !important;
  display: inline-block !important;
  font: 500 20px/1 "quadon", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  letter-spacing: 1px !important;
  margin: 0 -2px !important;
  padding: 17px 20px 16px !important;
  vertical-align: top !important;
  width: 100% !important;
  border-radius: 0px !important;

}

.uf_textbox:focus{
  border: 2px solid var(--ufOrange) !important;
  outline:none  !important;
  box-shadow: none !important;
  
}

.uf_formlabel{
  font: 500 20px/1 "quadon", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  color: rgba(51, 49, 50, 0.6) !important;
}

.login-image{
  background-image: url('./static/bg2.jpeg') !important;
  background-size: cover  !important; 
  background-repeat: no-repeat  !important;
  width: 100vw  !important; 
  height: 100vh  !important;
}

.table-head-row{
  color: white !important;
  background: rgba(0,0,0,0.5) !important;
}
.table-body-row{
  color: white !important;
  background: rgba(0,0,0,0.3) !important;
}

.filter-select{
  width: 200px !important;
  margin-right: 10px;
  margin-bottom: 10px;
  background:rgba(0,0,0,0.2) !important;
}

.bg-image{
  background-image: var(--bgImgUrl);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.table-row-hover:hover .table-body-row{
  background: red;
}

.table-hover tbody tr:hover {
  /* Change this color to your desired hover background color */
  --bs-table-hover-bg: rgba(0,0,0,0.3) !important;
  --bs-table-hover-color:white !important
}

.bg-pebble{
  background-image: url("./static/pebble.svg");
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}


.participant-container{
  margin-top: 10px;
}

.participant-container input{
  background: var(--containerColor) !important;
  border: none;
}

.participant-container textarea{
  
  background: var(--containerColor) !important;
  border: none;
}

.participant-container::-webkit-scrollbar{
  width: 6px;
}


.table-header-style{
  text-align: center !important;
}

.table-header-style th{
  background-color: rgba(79, 119, 170, 0.8) !important;
  color: white !important
}

.table-row-style{
  text-align: center;
}

.table-hover tbody tr:hover td{
  background-color:  rgba(79, 119, 170, 1) !important;
}

.react-bootstrap-table-sort-order{
  color: #005496;
}