/* Overlay to cover the entire screen with a dark transparent background */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it is on top of all other elements */
  }
  
  /* Container for the document preview */
  .preview-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  /* Buttons inside the preview window */
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  /* Style the buttons */
  .buttons button {
    margin-left: 10px;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .buttons button:hover {
    background-color: #0056b3;
  }

  /* Make the document name clickable */
.clickable-document-name {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .clickable-document-name:hover {
    color: #0056b3;
  }